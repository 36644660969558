import React, { useState, useEffect } from "react";
import axios from "axios";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";

function Feeds() {
  const [data, setData] = useState([]);
  const dvid = [
    "https://scontent.fdel1-8.fna.fbcdn.net/v/t42.1790-2/435544553_1118146052785574_5496529390991575727_n.mp4?_nc_cat=101&ccb=1-7&_nc_sid=55d0d3&efg=eyJybHIiOjU0MywicmxhIjo1MTIsInZlbmNvZGVfdGFnIjoic3ZlX3NkIiwidmlkZW9faWQiOjgzODk0ODMwNDczNDg5MH0%3D&_nc_ohc=E01bUSoxvRwQ7kNvgH61MzX&rl=543&vabr=302&_nc_ht=scontent.fdel1-8.fna&edm=AP4hL3IEAAAA&oh=00_AYBcR1o9sIk4lHM5b6-ZLd8daruGSPfE11dgQrgCnozrQQ&oe=668A9A61",
    "https://scontent.fdel1-2.fna.fbcdn.net/v/t42.1790-2/10000000_7853883484646044_3522910664614136040_n.mp4?_nc_cat=107&ccb=1-7&_nc_sid=55d0d3&efg=eyJybHIiOjEwNDUsInJsYSI6MzQ5NSwidmVuY29kZV90YWciOiJzdmVfc2QiLCJ2aWRlb19pZCI6MTA3MzU5NjA1NzAzODIwNH0%3D&_nc_ohc=2z-jjgTJMsIQ7kNvgEUUrZt&rl=1045&vabr=581&_nc_ht=scontent.fdel1-2.fna&edm=AP4hL3IEAAAA&oh=00_AYBHkFObTzsaQht-1RSjnxKxtTICdhb-2QG-vq27lPoTEA&oe=668AC3E6",
    "https://scontent.fdel1-7.fna.fbcdn.net/v/t42.1790-2/435629557_953642206294260_6313088262760485423_n.mp4?_nc_cat=103&ccb=1-7&_nc_sid=55d0d3&efg=eyJybHIiOjE1NTcsInJsYSI6NTEyLCJ2ZW5jb2RlX3RhZyI6InN2ZV9zZCIsInZpZGVvX2lkIjo3MjM1MzQwNTY1OTkxMzB9&_nc_ohc=AJLvq_N66vQQ7kNvgFavvXs&rl=1557&vabr=865&_nc_ht=scontent.fdel1-7.fna&edm=AP4hL3IEAAAA&oh=00_AYBIw2usj4KjMvPpvkhGtpqBUa-wxR2pX-AWX7cA4eJ7VA&oe=668AB494",
    "https://scontent.fdel1-5.fna.fbcdn.net/v/t42.1790-2/435584449_946289653863640_6109307746398277529_n.mp4?_nc_cat=110&ccb=1-7&_nc_sid=55d0d3&efg=eyJybHIiOjU1MCwicmxhIjo1MTIsInZlbmNvZGVfdGFnIjoic3ZlX3NkIiwidmlkZW9faWQiOjE3ODc0NDk0MDg0MjkxNzB9&_nc_ohc=p-c-jQxJQTsQ7kNvgF42ghN&rl=550&vabr=306&_nc_ht=scontent.fdel1-5.fna&edm=AP4hL3IEAAAA&oh=00_AYCHc1A-44HTqfSLyLBdi6yVv1pJsNyA_HGLbH0CUOyp0w&oe=668A9B40",
  ]

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          "https://graph.facebook.com/me?fields=first_name,posts{source,type}&access_token=EAALVZBuK2IgUBO5QW9tTynTV2bYXt8zSvBQk10f2oeFAlCGNu95W6V2FQuLe5VOtFgUAVlCPasnq6G8D3coKYZBvCBKz8XYrT7fty1hsjMIQ1JTHR7UY9rmQmqCpIsyHOMmCQezk1Iy8Y7JC0ZBCng5DUukmc6sZBkjDF40KdttYkMQMRkXuZA5HvL9yFZCZBEZB"
        );
        if (response.data.posts && response.data.posts.data) {
          let arrvideo = response.data.posts.data.filter((e) => e.type === "video");
          setData(arrvideo.slice(0, 4));
        }
      } catch (error) {
        setData(dvid);
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);

  return (
    <div className="h-full w-full grid grid-flow-col gap-3 overflow-x-scroll mb-12">
      <div
        className={`h-full md:w-[90px] md:mr-8 w-[100px] text-center flex gap-1 text-gray-700 text-xl md:text-3xl justify-center items-center thermite `}
      >
        <div className="mt-[-8px]">
          <KeyboardArrowLeftRoundedIcon />
        </div>
        VIDEO
      </div>
      {data.map((e, index) => (
            <div
              key={index}
              className={`h-[70vh] bg-green-200 w-[300px] rounded-md`}
            >
              <video
                className="w-full h-full object-cover rounded-md"
                muted
                controls
                loop
                autoPlay={index === 0 ? true : false}
              >
                <source
                  src={
                    e.source ? e.source : e
                  }
                  type="video/mp4"
                  preload="metadata"
                />
              </video>
            </div>
          ))}
    </div>
  );
}

export default Feeds;
